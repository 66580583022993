import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import Slider from './emulator/Slider.js'
import Header from './Header'
import Footer from './footer'
import Container from 'react-bootstrap/esm/Container';
import NotFond from './notFond'
import { useLayoutEffect } from 'react';
import axios from './axios'
import loader_img from '../images/loader.svg';
  
 function Contact() {

    let [isLoad,setIsLoad] = useState(false);
    let  [isError,setIsError] = useState(false);
    let  [validateEm,setValidateEm] = useState(true);
    let [token,setToken] = useState('');
    let [isSend,setIsSend] = useState(false);
    let [loadBefore,setLoadBefore] = useState(false);
    let [msgError,setMsgError] = useState(null);
    

    let fullName = useRef();
    let email = useRef();
    let subject = useRef();
    let adress = useRef();
    let phone = useRef();
    let tokenSq = useRef();
  
    useEffect(()  => {

      axios.get('get_token').then((response) => {
          if (response.data.token != undefined)
            setToken(response.data.token);
      });

    },[isSend,isError]);


    const validaTionEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    


    const sendMsg = e => {

      setLoadBefore(true);

      phone.current.removeAttribute('style');
      email.current.removeAttribute('style');
      subject.current.removeAttribute('style');
      fullName.current.removeAttribute('style');
      setValidateEm(true);
      
      setIsSend(false);
      setIsError(false);


      let isError=false;

      if (fullName.current.value ==''){
            isError = true;
            setMsgError('Entrez le nom complet');
            fullName.current.style.border = '1px solid red';
      }

      if (!email.current.value.match(validaTionEmail)){
        isError = true;
          setValidateEm(false);
          setMsgError("L'e-mail est incorrect");
          email.current.style.border = '1px solid red';
      }

      if (subject.current.value == ''){
        isError = true;
        setMsgError("Entrez le contenu du demander");
          subject.current.style.border = '1px solid red';
      }

      

      if (phone.current.value == ''){
        isError = true;
        setMsgError("Entrez le numero de Telephone");
        phone.current.style.border = '1px solid red';
      }

      
      
      if (!isError)
        {
          axios.post('contact',{
            fullName:fullName.current.value,
            subject:subject.current.value,
            phone:phone.current.value,
            adress:adress.current.value,
            email:email.current.value,
            token:tokenSq.current.value

          }).then((response) => {
            setLoadBefore(false);
            if (response.data.status==1)
            {
              setIsSend(true);
              phone.current.value='';
              email.current.value='';
              subject.current.value='';
              fullName.current.value='';

              const timeHideSend = setTimeout(()=>{

                setIsSend(false);
                clearTimeout(timeHideSend);
              },6000);
            }
            else{
              setIsError('error');
              setMsgError(response.data.msg);
            }
    
          })
          .catch((error) => {
            setIsError('error');
            setMsgError('Une erreur , veuillez réessayer');
            setLoadBefore(false);
          });
          
    
        }else
        {
          setLoadBefore(false);
          setMsgError('Une erreur , veuillez réessayer');
          setIsError(true);
        }

    }


  return (
  	<>

      <Header
       descHeader="&nbsp;&nbsp;"
       titleHeader="Contactez-nous"
       descHeader2="Vous pouvez nous contacter pour commander ou vous renseigner "
       classHeader="Header-contact"
       backgroundBody="ok"
       />

       
<section className="main-container gray-bg" style={{margiTop:" 0px;"}}>
<div className="Contact-com" id="Contact-us">

<Container>
      <div class="heading-page"><h2 class="heading-page-bigg"><center>Vous avez un projet ? Contactez-nous</center></h2></div>


      <div className="contact-in">

        <div className="row">

          <div className="col-md-6 contact-form-data">
            <div className="head-conact">
              <h2>Formulaire de contact:</h2>
              <hr />
            </div>

            <br /> 

            {isError==true && <div className="alert alert-danger">
            Veuillez entrer les champs requis
            {!validateEm && <div><b>.</b> L'email est faux</div>}
              </div>}

              {isError=='error' && <div className="alert alert-danger">
              {msgError}
              </div>}


              {isSend && <div className="alert alert-success">
              Votre demande a été envoyée et sera répondue par e-mail
              </div>}


            <div className="form-group row">
              <div className="col-md-6">
                <label for="name-form">Nom & Prénom*</label>
                <input ref={fullName} autocomplete="off" id="name-form" type="text" className="form-control" />
              </div>


              <div className="col-md-6">
                <label for="name-form">Adresse E-maill*</label>
                <input ref={email} type="text" className="form-control" />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-6">
                <label for="name-form">Telephone*</label>
                <input ref={phone} id="name-form" type="text" className="form-control" />
              </div>


              <div className="col-md-6">
                <label for="name-form">Adresse</label>
                <input ref={adress} type="text" className="form-control" />
              </div>
            </div>



            <div className="form-group"> 
              <label for="name-form">Veuillez écrire votre question ou demande</label>
              <textarea ref={subject} rows="8" cols="40" className="form-control"></textarea>

            </div>

            <div className="form-group">
              <button onClick={sendMsg} class="btn btn-info">ENVOYEZ LA DEMANDE</button>
              {loadBefore && <img width="30" style={{marginLeft:'20px'}} src={loader_img}/>}
              <input type="hidden" value={token} ref={tokenSq} />
            </div>


          </div>

          <div className="col-md-6">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe width="100%" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=27.14146051453888,%20-13.160295953373423&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                <a href="https://2piratebay.org"></a>
                <br />

                  <a href="https://www.embedgooglemap.net">embed custom google map</a>
                </div>
            </div>


            <br />

            <div className="head-conact">
              <h2>Contactez-nous:</h2>
              <hr />
            </div>

            <div className="my-info-soc">

              <div className="row">

                <div className="col-md-6 item-info-contact">
                  <span className="fa fa-phone-square"></span>
                  <span className="item-info-contact-span">
                    <div><b>Phone:</b> +212708797374</div>
                     <div><b>Email:</b> slateprojects.sp@gmail.com</div>
                  </span>
                </div>

                <div className="col-md-6 item-info-contact">
                  <span className="fa fa-map-marker"></span>
                  <span className="item-info-contact-span">
                    <div>MADINAT EL WAHDA bloc <br />  (g) - LAAYOUNE</div>
                     
                  </span>
                </div>

              </div>

            </div>

          </div>


          


        </div>

      </div>

</Container>




</div>
    </section>

       
       <Footer />

     </>);
  }

 export default Contact;