import { useEffect, useRef, useState } from 'react';
import axios  from './axios.js';


import logo from '../logo.svg';
import Rideux from './emulator/Rideux.js'
import Slider from './emulator/Slider.js'
import Header from './Header'
import Container from 'react-bootstrap/esm/Container'; 
import SlideShow from './SlideShow.js';
import InfoSoc from './info_soc';
import Footer from './footer'
import Alexa from './emulator/Alexa.js'
import Lamp from './emulator/Lamp.js'
import Shema1 from './emulator/Shema1.js';
import Shema2 from './emulator/Shema2.js';
import Porte from './emulator/Porte.js';
import Fenetre from './emulator/Fenetre.js';
import Volete1 from './emulator/Volete1.js';
import Volete2 from './emulator/Volete2.js';
import questImg from '../ques.png';




import loader_img from '../images/loader.svg';
import Domantique_image from '../doma.PNG';
import domantique_layer from '../images/domantique_layer.jpg';

//const baseURL = 'http://slateprojects.ma/cp_admin/api/';



axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

 function Home() {


  let [emulator,setEmulator] = useState(<Alexa />);
  let [curentActive,setCurentActive] = useState('');
  let [isLoad,setIsLoad] = useState(false);
  let [effectLoad,setEffectLoad] = useState(false);
  const [dataHome,setDataHome] = useState({
    site_title:null,
    header_desc:null,
    header_title:null,
    desc_domotique:null,
    circle_eclai:null,
    circle_multi:null,
    circle_arro:null,
    circle_moto:null,
    circle_secu:null,
    circle_temp:null,
    emul:null,
    bottom_emul:null,
    footer_desc:null
  });

  useEffect(()=>{
      setCurentActive(document.getElementById('Alexa'));
      setIsLoad(true);
      console.log(dataHome.header_desc);


axios.get('home_page').then((response) => {
        setDataHome(response.data.data);
        console.log(response.data.data);
        setEffectLoad(true);
        console.log(response.data.data.desc_domotique);

      })
      .catch((error) => {
        console.log(error);
      });
      
  },[]);

  const CompArr = {'Slider':<Slider />,
                   'Alexa':<Alexa />,
                   'Lamp':<Lamp />,
                    'Shema1':<Shema1 />,
                    'Shema2': <Shema2 />,
                    'Rideux':<Rideux />,
                    'Porte':<Porte />,
                    'Fenetre':<Fenetre />,
                    'Volete1':<Volete1 />,
                    'Volete2': <Volete2 />
                  };

   let ChangePattern = (e) => {
    setIsLoad(false);
    e.preventDefault()

   let theId = e.target.id;
   setEmulator(CompArr[theId]);
   curentActive.removeAttribute('style');
   setCurentActive(e.target);
   e.target.style.background = '#574fdb';
   e.target.style.color = '#fff';

   setTimeout(() => {
    setIsLoad(true);

   },1000);
 }

  return (


  <>
      <Header classHeader="Header-p home-hae" dataHome={dataHome} isLoadPage={effectLoad} isHome={true} />


    <section className="content-page-block-medium">

    <div className="heading-page"><h1 className="heading-page-bigg">LA DOMOTIQUE <img src={questImg} /></h1></div>


     
      <div style={{display:'none'}} className="video-animat">
        <h4 style={{width:'100%',position:'absolute',top:'47%'}}>Qu'offrons-nous </h4>
      </div>


    </section>

     <section className="content-page-block-large">
      <Container>

        <div className="row home-domantique">

          <div className="col-md-6 text-domontique">
          <p dangerouslySetInnerHTML={{__html: dataHome.desc_domotique}} />
          </div>


          <div style={{marginTop: '21px',padding:'0'}} className="col-md-6">
            <img src={domantique_layer} />
          </div>
        </div>

      </Container>

      </section>

      <SlideShow />
       <InfoSoc isLoadPage={effectLoad} dataHome={dataHome} />

      


    <section className="content-page-block-large">
      <Container>
        <div className="heading-page"><h1 className="heading-page-bigg">Simulation</h1></div>

        <h3 className="description-block">{dataHome.emul}</h3>
        
        
        <div className="emulateur row">

          <div className="col-md-3 nav-emulator">
            <nav>
              <ul>
                
                <li><a style={{background:'#574fdb',color:'#fff'}} id="Alexa" href="#" onClick={ChangePattern}>Assistant intelligent</a></li>
                <li><a id="Porte" onClick={ChangePattern} href="#">Porte Smart Lock </a></li>
                <li><a id="Rideux" onClick={ChangePattern} href="javascript:void(0)" >Parking Garage</a></li>
              </ul>
            </nav>
          </div>

          <div className="col-md-6 center-emulator">
            <div className="content-emulator">
              {(!isLoad) && <div className="loadding-emul"><img src={loader_img} /></div>}
              {emulator}
            </div>
          </div>

          <div className="col-md-3 nav-emulator">
            <nav>
              <ul>
                
                <li><a id="Fenetre" onClick={ChangePattern} href="#">Fenétre ouverte ou fermée </a></li>
                <li><a id="Volete1" onClick={ChangePattern} href="#">Rideaux de chambre </a></li>
                <li><a id="Volete2" onClick={ChangePattern} href="#">Volet roulant de cuisine</a></li>
              </ul>
            </nav>
          </div>
          
          
          <svg viewBox="0 0 300 322">
          <title>Background Gradient</title>
          <defs>
          <linearGradient id="gradient-2" gradientTransform="rotate(90)">
          <stop offset="5%" stop-color="rgba(233,244,255,0)"></stop>
          <stop offset="95%" stop-color="rgba(233,244,255,1)"></stop>
          </linearGradient>
          </defs>
          <path fill="url(#gradient-2)" d="M300,196.58v-71.16c0-31.57-16.84-60.75-44.19-76.53l-61.63-35.58c-27.34-15.79-61.03-15.79-88.37,0 L44.19,48.89C16.84,64.67,0,93.85,0,125.42v71.16c0,31.57,16.84,60.75,44.19,76.53l61.63,35.58c27.34,15.79,61.03,15.79,88.37,0 l61.63-35.58C283.16,257.33,300,228.15,300,196.58z"></path>
          </svg>

        </div>

        

      </Container>
    </section>


<br /><br /><br /><br /><br /><br />

    <section className="content-page-block-large">
      <Container>
       
        <p dangerouslySetInnerHTML={{__html: dataHome.bottom_emul}} className="description-block" />
        <div className=" row">

            <div className="col-md-3">

              <div className="ssssss">Contrôle d’accès</div>
            </div>

            <div className="col-md-3">

              <div className="ssssss">Contrôle d’éclairage</div>
            </div>


            <div className="col-md-3">

              <div className="ssssss">Système de sécurité</div>
            </div>
            





            <div className="col-md-3">

            <div className="ssssss">	Chauffage et climatisation </div>
            </div>


            <div className="col-md-3">

            <div className="ssssss">		Système d'irrigation </div>
            </div>


            <div className="col-md-3">

            <div className="ssssss">stores intelligents WiFi </div>
            </div>

            <div className="col-md-3">

<div className="ssssss">verrouillage automatique des portes et des volets</div>
</div>

<p className="description-block">Chaque système est conçu de façon à répondre parfaitement aux besoins et aux infrastructures de notre clientèle.</p>




          </div>

          
      
      </Container>
    </section>



      <Footer desc={dataHome.footer_desc} />
    </>

    

    );

}

export default Home;