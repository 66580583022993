import { useEffect, useRef, useState } from 'react';
import Slider from './emulator/Slider.js'
import Header from './Header'
import Footer from './footer'
import Container from 'react-bootstrap/esm/Container';
import Carousel from 'react-bootstrap/Carousel';
import NotFond from './notFond'

import axios from './axios'



import slid1 from '../images/solai/1.jpg';
import slid2 from '../images/solai/2.jpg';
import slid3 from '../images/solai/3.png';
  
 function Solai() {

  let [curentActive,setCurentActive] = useState('');
  let [isLoadPage,setIsLoad] = useState(false);
  let [listRow,setListRow] = useState('');
  let [data,setData] = useState({
      headerTitle:null,
      headerDesc:null,
      info:'Information',
      descPage:null,
      paneux:[],
      galeyTitle:null,
      galeyDesc:null,
      galery:null
  });
  
    useEffect(()=>{



      axios.get('paneu_solai/').then((response) => {
        if (response.data.status){

          setData(response.data.data);

          console.log(response.data.data.paneux);
          
          if (response.data.data.paneux.length > 0)
          {
            var listRo = '';
            if (response.data.data.paneux.length < 2){
              listRo += `
                      <div class="col-sm-12 row-once-paneu">
                        <div style="height:350px;" class="box-style-1 white-bg object-non-visible animated object-visible fadeInUpSmall" data-animation-effect="fadeInUpSmall" data-effect-delay="400">
                        <img src="http://slateprojects.ma/cp_admin/uploads/${response.data.data.paneux[0].icon}" />
                            <h3 style="color:#4a4a4a"> ${response.data.data.paneux[0].title} </h3>
                            <p class="elementor-icon-box-description" ><strong>✓</strong> ${response.data.data.paneux[0].desc}<br />
                              <strong>✓</strong> Vos factures baissent<br />
                              <strong>✓</strong> Vos besoins en énergie sont couverts</p>

                        </div>
                    </div>`;
              }
              else{
              for(let x =0; x<response.data.data.paneux.length; x++){
                
                
                  listRo += ` <div class="col-sm-4">
                  <div style="height:350px;" class="box-style-1 white-bg object-non-visible animated object-visible fadeInUpSmall" data-animation-effect="fadeInUpSmall" data-effect-delay="400">
                  <img width="100" src="http://slateprojects.ma/cp_admin/uploads/${response.data.data.paneux[0].icon}" />
                      <h3 style="color:#4a4a4a"> ${response.data.data.paneux[0].title} </h3>
                      <p class="elementor-icon-box-description" ><strong>✓</strong> ${response.data.data.paneux[0].desc}<br />
                        <strong>✓</strong> Vos factures baissent<br />
                        <strong>✓</strong> Vos besoins en énergie sont couverts</p>

                  </div>
              </div>`;
                

              }
          }

            setListRow(listRo);

            console.log(listRo);
          }

        }
        setIsLoad(true);
  
      })
      .catch((error) => {
        setIsLoad(true);
      });

    },[]);
  

  
     let ChangePattern = (e) => {
      setIsLoad(false);
      e.preventDefault()
  

    }

     //d’installation solaire ! Nos experts ont réalisé des kits spécifiques pour chaque utilisation. Chacun de nos kits ont été conçus afin de vous offrir une performance optimale avec du matériel de haute qualité.  Vous trouverez forcément le kit solaire qu’il vous faut ! 


  return (
  	<>

      <Header
       descHeader="Énergie solaire"
       titleHeader=""
       descHeader2={data.headerTitle}
       classHeader="Header-electriciti-so"
       imageHeader="http://slateprojects.ma/cp_admin/assets/editor/php/up/MzI1Njcq.jpg"
       isLoadPage={isLoadPage}
       />

<section className="main-container gray-bg" style={{margiTop:" 0px;"}}>

<div className="main">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h1 id="information-2" className="text-center title">{data.info}</h1>
                <div className="separator">
                <p dangerouslySetInnerHTML={{__html:data.descPage}} />

                </div>
                <div className="row" dangerouslySetInnerHTML={{__html:listRow}}>
                    




                </div>
            </div>
        </div>
    </div>
</div>

</section>



<section style={{display:'none'}} className="content-page-block-large">
      <Container>
        <div className="heading-page"><h1 className="heading-page-bigg">Galery</h1></div>        




        <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slid1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>100% renouvelable et gratuit.</h3>
          <p>
ROI plutôt que de payer des factures de services publics.
Pratiquement aucun entretien car les panneaux solaires durent plus de 30 ans.
Il crée des emplois en embauchant des fabricants de panneaux solaires, des installateurs solaires, etc. et aide ainsi l'économie.</p>
        </Carousel.Caption>
      </Carousel.Item>



      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slid2}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Écologique.</h3>
          <p>

          Il n'y a pas d'émissions toxiques ou polluantes dans l'air.
Réduire l'utilisation des combustibles fossiles.
Une énergie de plus en plus compétitive.
Réduit le besoin de dépendre du réseau électrique ou du gaz naturel..</p>
        </Carousel.Caption>
      </Carousel.Item>





            <Carousel.Item>
        <img
          className="d-block w-100"
          src={slid3}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>développement de la technologie.</h3>
          <p>
          La technologie dans l'industrie de l'énergie solaire progresse constamment et les améliorations se poursuivront à l'avenir. Les innovations en physique quantique et en nanotechnologie peuvent augmenter l'efficacité des panneaux solaires et doubler, voire tripler, les apports électriques des systèmes d'énergie solaire..</p>
        </Carousel.Caption>
      </Carousel.Item>


      
      
    </Carousel>
        


        

      </Container>
    </section>

       
       <Footer />

     </>);
  }

 export default Solai;