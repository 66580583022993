import './slide-show.css';
import { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import camera_img from '../images/p-camera.png'
import shely_img from '../images/p-shely.png'
import alexa from '../images/40.png'


//import iconLamp from '../images/idea.png'


function SlideShow() {


  let [currentSlid,setCurrentSlid] = useState(0);

  let background = ['linear-gradient(276deg, #198783cf 25%, #198783 68%, #082c2b 97%)',
                    'linear-gradient(276deg, #0545d2c2 25%, #0545d2 68%, #132449 97%)',
                    'linear-gradient(276deg, rgba(131,18,186,0.8289443455385358) 25%, rgba(131,18,186,0.9974836713812324) 68%, rgba(76,13,107,1) 97%)'
                   ];
            

  let sliderHandler = (e) => {

      let allItem = document.querySelectorAll('#slide-show-custom .slide-show-item');

      let nextItem = currentSlid+1,
          prevItem = currentSlid-1;
      

       if (currentSlid >= (allItem.length-1)){
            nextItem = 0;
       }

      if (currentSlid<=0){
            prevItem = allItem.length-1;
      }

      for (let i=0; i<allItem.length;i++)
      {

        
         
         if (i==currentSlid)
         {
            /*slide-show-img*/

            let animatePos = 0,
            opacityElm=1,
            animatePos2=300,
            opacityElm2 = 0;

            if (e.target.getAttribute('data-dir') == 'next'){
               var oldItem = nextItem;
            }
            else{
               var oldItem = prevItem;
            }

            allItem[oldItem].style.opacity = 0;
            allItem[oldItem].style.visibility = 'visible';

            
            let intervalSet = setInterval(()=>{
               if (animatePos>=300)
                  {  

                     allItem[oldItem].querySelector('.slide-show-img').style.right = '-'+animatePos2+'px';
                     allItem[oldItem].querySelector('.slide-show-content').style.left = '-'+animatePos2+'px';

                     opacityElm2+=0.04;

                     allItem[oldItem].style.opacity = opacityElm2;

                      allItem[i].style.visibility='hidden';

                      setCurrentSlid(oldItem);

                      document.getElementById('slide-show-custom').style.background = background[oldItem];

                     animatePos2-= 10;

                     if (animatePos2 <= 0)
                     {
                        clearInterval(intervalSet);
                        return;
                    }

                  }
                  else{

                     opacityElm-=0.04;

                     allItem[i].style.opacity = opacityElm;

                     animatePos+=10
                     allItem[i].querySelector('.slide-show-img').style.right = '-'+animatePos+'px';
                     allItem[i].querySelector('.slide-show-content').style.left = '-'+animatePos+'px';               
                  
                }

            },10);

         }

      }

      //allItem[currentSlid].style.visibility = 'hidden';
  }

  return (
    <div style={{background:background[0]}} className="slide-show" id="slide-show-custom">

      <Container>
            <div className="slide-show-in">
               <div data-index="0" style={{visibility:'visible'}} className="slide-show-item">
                  <div className="slide-show-content">
                      <h1 className="head-item-slide">&nbsp; </h1>
                      <h1 className="head-old-item-slide">Caméra de <span>surveillance</span>&<span>WIFI</span></h1>
                      <h2 className="desc-item-slide">Détection mouvement, avec envoi d'alarme et de notifications sur votre téléphone</h2>
                  </div>
                  <div className="slide-show-img">
                     <img src={camera_img} />
                  </div>
               </div>


               <div data-index="1" className="slide-show-item">
                  <div className="slide-show-content">
                      <h1 className="head-item-slide">&nbsp; </h1>
                      <h1 className="head-old-item-slide">Capteurs et contrôleurs intelligents  <span>wifi </span></h1>
                      <h2 className="desc-item-slide">Prenez le contrôle de vos dispositifs électriques depuis votre téléphone et par la voix</h2>
                  </div>
                  <div className="slide-show-img">
                     <img src={shely_img} />
                  </div>
               </div>





               <div data-index="2" className="slide-show-item">
                  <div className="slide-show-content">
                      <h1 className="head-item-slide">&nbsp;</h1>
                      <h1 className="head-old-item-slide">Enceinte Assistant  <span>vocal</span></h1>
                      <h2 className="desc-item-slide">Les enceintes connectées s’invitent chez vous, n’hésitez plus et simplifiez-vous les tâches du quotidien</h2>
                  </div>
                  <div className="slide-show-img">
                     <img src={alexa} />
                  </div>
               </div>












               <span data-dir="next" onClick={sliderHandler} class="fa fa-chevron-left arrow-slid arrow-left-slider"></span>
               <span data-dir="prv" onClick={sliderHandler} class="fa fa-chevron-right arrow-slid arrow-right-slider"></span>
            </div>
      </Container>


    </div>
  );
}

export default SlideShow;
