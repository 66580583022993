import { useEffect, useInsertionEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container';
import cssStyle from './row-product.css';

import product1 from '../../images/CAMERA/1.png'
import product2 from '../../images/CAMERA/2.jpg'
import product3 from '../../images/CAMERA/3.jpg'
import axios from '../axios.js'
import loader_img from '../../images/loader.svg';

function MydModalWithGrid(props) {
  let theId = props.data;
  console.log();
  
  let [loadModal,setLoad] = useState(true);
  let [theProduct,setTheProduct]  = useState(null);
  let [errorModal,setErrorModal]  = useState(null);

  useEffect(() => {
      axios.get('get_the_product/id-'+theId).then((response) => {
        if (response.data.status){
          setTheProduct(response.data.data);
        }
        else{
          setErrorModal('Il n\'y a pas de produits');
        }

        setLoad(false);
      })
      .catch((error) => {
        setErrorModal('Il n\'y a pas de produits');
      });
});


let attr_product= null;
var attrs = null;

if (theProduct!=null){
 let attr_product = theProduct.attr.split('|');
 var attrs = attr_product.map((data,index) => 

    <li key={index}>{data}</li>
  );
}



return (
    <Modal size="lg" {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {theProduct != null && theProduct.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
        {loadModal && <center><br /><br /><img style={{width:'50px'}} src={loader_img} /></center>}

          <Row>
            <Col className="the_img" md={4}>
              {(theProduct != null && theProduct != undefined) && <img src={theProduct.img} />}
            </Col>


            <Col md={8}>
              <h3 className="modal-title-product">{theProduct != null && theProduct.nom}</h3>
              <ul className="row-attr-list">
                {attrs}
              </ul>
              <div className="clr"></div>
              <div className="btn-achat">
              { theProduct != null && <a href={"https://wa.me/212708797374?text="+ encodeURI("Bonjour, j'ai une question sur ce produit "+theProduct.nom)} target="_blank" className='btn btn-primary'>Acheter</a> }
              </div>
            </Col>

          </Row>

            {theProduct != null && <div className="body-desc" dangerouslySetInnerHTML={{__html: theProduct.content}} />}

            


        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
  
}

 function RowProducts(props) {
const [modalShow, setModalShow] = useState(false);
const [theData, setData] = useState(null);

const handleClick = (e,k) => {
   
   e.preventDefault();
    setModalShow(true);
    setData(k);
  }

  const listItems = props.data[props.cat].map((data,index) =>
        
          <div data_prod={index} onClick={(e) => { handleClick(e,data.id)}} key={index}  className="col-lg-3 col-md-4 col-sm-6 item-product">
              <a  href="#" style={{position:'relative',display:'block'}}>
                <div className="item-product-in">
                    <div className="item-product-img"> <img src={data.img} /></div>

                    <div className="products-info">
                      <b>{data.name}</b>
                      <p>{data.desc}</p>

                      <div className="product-desc">
                        {data.content.substring(0,80)}...                      
                      </div>
                    </div>

                </div>
              </a>

          </div>

        

);

  return (
  	<div className="content-products">
  {modalShow && <MydModalWithGrid data={theData} show={modalShow} onHide={() => setModalShow(false)} /> }

      <Container>


      	<div className="row"> 

      
        {listItems}






        </div>

	   </Container>

     <br />

     <br />

     <br />

     <br />
     <br />
     <br />

     </div>
  );
  }

 export default RowProducts;