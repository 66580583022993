import { useEffect, useRef, useState } from 'react';
import Slider from './emulator/Slider.js'
import Header from './Header'
import Footer from './footer'
import Container from 'react-bootstrap/esm/Container';
import NotFond from './notFond'

import axios from './axios'


import Shema1 from './emulator/Shema1.js';
import Shema2 from './emulator/Shema2.js';
import Shema3 from './emulator/Shema3.js';
import Shema4 from './emulator/Shema4.js';
import Porte from './emulator/Porte.js';
import Fenetre from './emulator/Fenetre.js';
import Volete1 from './emulator/Volete1.js';
import Volete2 from './emulator/Volete2.js';
import loader_img from '../images/loader.svg';
import Domantique_image from '../doma.PNG';
import domantique_layer from '../images/domantique_layer.jpg';
  
 function Electriciter() {
    let [emulator,setEmulator] = useState(<Shema1 />);
    let [curentActive,setCurentActive] = useState('');
    let [isLoadPage,setIsLoad] = useState(false);
    let [listRow,setListRow] = useState('');
    let [data,setData] = useState({
        headerTitle:null,
        headerDesc:null,
        info:'Information',
        descPage:null,
        paneux:[],
        schemaTitle:null,
        schemaDesc:null
    });
  
    useEffect(()=>{
        setCurentActive(document.getElementById('Shema1'));


        axios.get('electricite/').then((response) => {
          if (response.data.status){

            setData(response.data.data);

            console.log(response.data.data.paneux);
            
            if (response.data.data.paneux.length > 0)
            {
              var listRo = '';
              if (response.data.data.paneux.length < 2){
                listRo += `<div class="col-sm-12 row-once-paneu">
                          <div class="box-style-1 white-bg object-non-visible animated object-visible fadeInUpSmall" data-animation-effect="fadeInUpSmall" data-effect-delay="0">
                              <img src="http://slateprojects.ma/cp_admin/uploads/${response.data.data.paneux[0].icon}" />
                              <h3> ${response.data.data.paneux[0].title} </h3>
                              <p>${response.data.data.paneux[0].desc} </p>

                          </div>
                        </div>`;
                }
                else{
                for(let x =0; x<response.data.data.paneux.length; x++){
                  
                  
                    listRo += `<div class="col-sm-4">
                            <div  class="box-style-1 white-bg object-non-visible animated object-visible fadeInUpSmall" data-animation-effect="fadeInUpSmall" data-effect-delay="0">
                                <img width="200" height="150" src="http://slateprojects.ma/cp_admin/uploads/${response.data.data.paneux[x].icon}" />
                                <h3> ${response.data.data.paneux[x].title} </h3>
                                <p>${response.data.data.paneux[x].desc} </p>

                            </div>
                          </div>`;
                  

                }
            }

              setListRow(listRo);

              console.log(listRo);
            }

          }
          setIsLoad(true);
    
        })
        .catch((error) => {
          setIsLoad(true);
        });

        
        
    },[]);
  
    const CompArr = {
                      'Shema1':<Shema1 />,
                      'Shema2': <Shema2 />,
                      'Shema3': <Shema3 />,
                      'Shema4': <Shema4 />
                      
                    };
  
     let ChangePattern = (e) => {
      setIsLoad(false);
      e.preventDefault()
  
     let theId = e.target.id;
     setEmulator(CompArr[theId]);
     curentActive.removeAttribute('style');
     setCurentActive(e.target);
     e.target.style.background = '#574fdb';
     e.target.style.color = '#fff';
  
     setTimeout(() => {
      setIsLoad(true);
  
     },1000);
    }

  return (
  	<>

      <Header
       descHeader="ELECTRICITE"
       titleHeader=""
       descHeader2={data.headerTitle}
       classHeader="Header-electriciti"
       imageHeader="http://slateprojects.ma/cp_admin/assets/editor//php/up/NjIwNzYq.png"
       isLoadPage={isLoadPage}
      />

       
<section className="main-container gray-bg" style={{margiTop:" 0px;"}}>

<div className="main">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h1 id="information-2" className="text-center title">{data.info}</h1>
                <div className="separator">
                  <p dangerouslySetInnerHTML={{__html:data.descPage}} />
                </div>
                <div className="row" dangerouslySetInnerHTML={{__html:listRow}} />
            </div>
        </div>
    </div>
</div>

</section>



<section className="content-page-block-large">
      <Container>
        <div className="heading-page"><h1 className="heading-page-bigg">{data.schemaTitle}</h1></div>

        
        
        <div className="emulateur row">

          <div className="col-md-3 nav-emulator">
            <nav>
              <ul>
                
                <li><a id="Shema1" onClick={ChangePattern} href="#" >Schema de la chambre</a></li>
                <li><a id="Shema2" onClick={ChangePattern} href="#">Schema de la cuisine</a></li>
              </ul>
            </nav>
          </div>

          <div className="col-md-6 center-emulator">
            <div className="content-emulator">
              {(!isLoadPage) && <div className="loadding-emul"><img src={loader_img} /></div>}
              {emulator}
            </div>
          </div>

          <div className="col-md-3 nav-emulator">
            <nav>
              <ul>
                <li><a id="Shema3" onClick={ChangePattern} href="#">Schema de la chambre 2 </a></li>
                <li><a id="Shema4" onClick={ChangePattern} href="#">Schema du Salon </a></li>
              </ul>
            </nav>
          </div>
          
          
          <svg viewBox="0 0 300 322">
          <title>Background Gradient</title>
          <defs>
          <linearGradient id="gradient-2" gradientTransform="rotate(90)">
          <stop offset="5%" stop-color="rgba(233,244,255,0)"></stop>
          <stop offset="95%" stop-color="rgba(233,244,255,1)"></stop>
          </linearGradient>
          </defs>
          <path fill="url(#gradient-2)" d="M300,196.58v-71.16c0-31.57-16.84-60.75-44.19-76.53l-61.63-35.58c-27.34-15.79-61.03-15.79-88.37,0 L44.19,48.89C16.84,64.67,0,93.85,0,125.42v71.16c0,31.57,16.84,60.75,44.19,76.53l61.63,35.58c27.34,15.79,61.03,15.79,88.37,0 l61.63-35.58C283.16,257.33,300,228.15,300,196.58z"></path>
          </svg>

        </div>

        

      </Container>
    </section>

       
       <Footer />

     </>);
  }

 export default Electriciter;