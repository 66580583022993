import './info-soc.css';
import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container'; 
import iconLamp from '../images/idea.png'
import thermometer from '../images/thermometer.png'
import doorLock from '../images/door-lock.png';
import music from '../images/music.png';
import alarmClock from '../images/alarm-clock.png';
import waterTap from '../images/water-tap.png';
import access_work from '../access-work.png';
import access_work2 from '../access-work2.png';
import storeintelligent from '../images/store-intelligent.png';




function InfoSoc(props) {
	let [widthAnimat,setWidthAnimat] = useState(50);
	let [mouseUp,setmouseUp] = useState('not');

	let [infoCheckTitle,setInfoCheckTitle] = useState('');
	let [infoCheckDesc,setInfoCheckDesc] = useState('');
	const [modalShow, setModalShow] = useState(false);
	
	useEffect(() => {
	  
	  let imfFix = document.getElementById('get_img_fix');
		  let starLeft = (document.body.offsetWidth-imfFix.offsetWidth)/2;
		  let clientAll = (imfFix.offsetWidth/2);
		  setWidthAnimat((clientAll*100)/imfFix.offsetWidth);
  

		  document.querySelectorAll('.item-more-ifo').forEach(function (elem){
				elem.addEventListener('click',(e) => {
					if (window.innerWidth > 1000)
					return false;

					//console.log(elem.getElementsByTagName('h4')[0].nextSibling.nodeType);
					setInfoCheckTitle(elem.getElementsByTagName('h4')[0].innerText);
					setInfoCheckDesc(elem.getElementsByTagName('p')[0].innerText.replace(elem.getElementsByTagName('h4')[0].innerText,''));
					setModalShow(true);
				  });
		  });
  
	},[]);
	
  
	let resizeTol = (e) => {
	  if (mouseUp=='ok'){
		  let imfFix = document.getElementById('get_img_fix');
		  let starLeft = (document.body.offsetWidth-imfFix.offsetWidth)/2;
		  let clientAll = (e.clientX-starLeft);
		  setWidthAnimat((clientAll*100)/imfFix.offsetWidth);
	  }
  
	}

let hideModal = e => {
	setModalShow(false);
}

let showModal = e => {
	setModalShow(true);
}


  return (
<>

<Modal size="lg" onHide={hideModal} show={modalShow} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
			{infoCheckTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
			<center>{infoCheckDesc}</center>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Close</Button>
      </Modal.Footer>
    </Modal>
    <div className="Info-soc">
      <Container>
      <div className="info-soc-in">
      <div className="slide-img-custom">
        <div id="get_img_fix" onMouseMove={resizeTol} onMouseUp={() => setmouseUp('not')} style={{position: 'absolute',top: '0',left: '0',backgroundSize: '100% 100%',backgroundImage: 'url('+access_work+')'}}  className="slide-img"></div>
        
        <div onMouseMove={resizeTol} onMouseUp={() => setmouseUp('not')} style={{position: 'absolute',top: '0',left: '0',backgroundSize: 'auto 100%',backgroundImage: 'url('+access_work2+')',width:widthAnimat+'%'}} className="slide-img"></div>
        <span onMouseDown={() => setmouseUp('ok')}  onMouseUp={() => setmouseUp('not')} style={{left:widthAnimat+'%'}} className="resize"></span>
       </div>

          
      			<div style={{top:'-9%'}} className="item-more-ifo">
      				<div className="item-more-in">
                     <img className="img-item-info" src={iconLamp} />
      					
      					<p className="content-item-info">
						  <h4>Eclairage</h4>
						  {props.dataHome.circle_eclai}
						  </p>
      				</div>
      			</div>


      			<div style={{top:'18%',right:'-2%'}}  className="item-more-ifo">
      				<div className="item-more-in">
                     <img className="img-item-info" src={thermometer} />
      					
      					<p style={{left:'-130%'}} className="content-item-info"><h4>Température </h4>
						  {props.dataHome.circle_temp}
						  </p>
      				</div>
      			</div>



      			<div style={{top:'18%',left:'-2%'}} className="item-more-ifo">
      				<div className="item-more-in">
                     <img  className="img-item-info" src={music} />
      					
      					<p style={{left:'17%'}} className="content-item-info"><h4>Multimédia </h4>
						  {props.dataHome.circle_multi}
						</p>
      				</div>
      			</div>




      			<div style={{bottom:'18%',left:'-2%'}} className="item-more-ifo">
      				<div className="item-more-in">
                     <img  className="img-item-info" src={storeintelligent} />
      					
      					<p style={{left:'17%'}} className="content-item-info"><h4>Motorisation</h4>
						  {props.dataHome.circle_moto}
						  </p>
      				</div>
      			</div>



      			<div style={{bottom:'18%',right:'-2%'}} className=" item-more-ifo">
      				<div className="item-more-in">
                     	<img  className="img-item-info" src={alarmClock} />
      					
      					<p style={{left:'-130%'}} className="content-item-info"><h4> sécurité</h4>
						  {props.dataHome.circle_secu}
						</p>
					</div>
      			</div>



      			<div style={{bottom:'-9%'}} className="item-more-ifo">
      		     <div className="item-more-in">
                  <img  className="img-item-info" src={waterTap} />
      					<p style={{bottom:'97%'}}  className="content-item-info"><h4>Arrosage </h4>
						  
						  {props.dataHome.circle_arro}  
						</p>
      				</div>
      			</div>

        
         </div>
      </Container>
    </div></>
  );
}

export default InfoSoc;
