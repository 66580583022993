import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Products from './Compenets/products';
import Electriciter from './Compenets/Electriciter';
import Solai from './Compenets/Solai';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter,Routes,Route} from 'react-router-dom'
import NotPage from './Compenets/NotPage'
import Container from 'react-bootstrap/Container';
import Home from './Compenets/Home'
import 'bootstrap/dist/css/bootstrap.min.css'

import Contact from './Compenets/Contact';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
  <React.StrictMode>
    
    <div className="global-page">
      <BrowserRouter basename="/">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="Products" element={<Products />} />
          <Route path="Products/:id" element={<Products />} />
          <Route path="electricite" element={<Electriciter />} />
          <Route path="Solai" element={<Solai />} />
          <Route path="*" element={<NotPage />} />
          <Route path="Contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
     </div>
  </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
