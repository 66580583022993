import { useEffect, useRef, useState } from 'react';
import chamber1 from '../../images/eml/fenetre-o.jpg';
import  chamber1_s from '../../images/eml/fenetre-f.jpg';

function Fenetre() {

  let [lampShow,setLampShow] = useState('visible');
  
  useEffect(() => {

  },[]);
  



  return (
    <>
       
        <div className="lamp-div-over">
          <img style={{width:'630px'}} src={chamber1} />
        </div>
        <div style={{visibility: lampShow}} className="lamp-show2">
          <img style={{width:'630px'}} src={chamber1_s} />
       </div>


       <div className="imulator-phone">
          <div className="phon-conten">
            <span style={{background:'#574fdb'}} onClick={() => { setLampShow('hidden') }} className="fa fa-unlock button-phone"></span>
            <div></div>
            <span style={{background:'#1d6c8b'}} onClick={() => { setLampShow('visible') }} className="fa fa fa-lock button-phone"></span>
         </div>
       </div>
    </>
  );
}

export default Fenetre;
