import { useEffect, useRef, useState } from 'react';
import chamber1 from '../../images/eml/salon1.jpg';
import  chamber1_s from '../../images/eml/salon2.jpg';

function Shema4() {

  let [lampShow,setLampShow] = useState('visible');
  
  useEffect(() => {

  },[]);
  



  return (
    <>
       
        <div className="lamp-div-over">
          <img style={{width:'630px'}} src={chamber1} />
        </div>
        <div style={{visibility: lampShow}} className="lamp-show2">
          <img style={{width:'630px'}} src={chamber1_s} />
       </div>

       <div className="imulator-back">
          <div className="phon-conten">
            <span style={{background:'#574fdb'}} onClick={() => { setLampShow('hidden') }} className="fa fa-picture-o button-phone"></span>
            <div></div>
            <span style={{background:'#1d6c8b'}} onClick={() => { setLampShow('visible') }} className="fa fa-sliders button-phone"></span>
         </div>
       </div>
    </>
  );
}

export default Shema4;
