import { useEffect, useRef, useState } from 'react';
import imgRide from '../../rideu.png';




function Rideux() {


  let [mouseUp,setMouseUp] = useState(false);
  let [courseRido,setCourse] = useState(-93);
  let [intervalUp,setintervalUp] = useState(null);

  function downRideu(e){
    setMouseUp(true);

    if (intervalUp!=null)
      clearInterval(intervalUp);
    
    setintervalUp(setInterval(() => {

        if (courseRido >= 1)
          return; 

        setCourse(courseRido++);

    },50));
  }

  function downRideuClose()
  {
    setMouseUp(false);

    if (intervalUp!=null)
      clearInterval(intervalUp);
  }


  function upRideu(e){
    setMouseUp(true);

    if (intervalUp!=null)
      clearInterval(intervalUp);
    
    setintervalUp(setInterval(() => {

        if (courseRido <= -93)
          return; 

        setCourse(courseRido--);

    },50));
  }

  function upRideuClose()
  {
    setMouseUp(false);

    if (intervalUp!=null)
      clearInterval(intervalUp);
  }

  return (
    <>
       <img width="100%" src={imgRide} />
       <div className="lam-rideu-parent">
        <div style={{'top':courseRido+'px'}} className="lamRido"></div>
       </div>
       

       <button onMouseDown={upRideu} onMouseUp={upRideuClose} className="btn btn-success rido-buton-top"><i className="fa fa-chevron-up"></i></button>
       <button onMouseDown={downRideu} onMouseUp={downRideuClose} className="btn btn-danger rido-buton-bottom"><i className="fa fa-chevron-down"></i></button>
    </>
  );
}

export default Rideux;
