import { useEffect, useRef, useState } from 'react';
import logo from '../logo.svg';
import Rideux from './emulator/Rideux.js'
import Slider from './emulator/Slider.js'
import Header from './Header'
import Footer from './footer'
import Container from 'react-bootstrap/esm/Container';
import TabBarCat from './products/tab_bar_cat'
import {useParams} from "react-router-dom";
import RowProducts from './products/row_products'
import NotFond from './notFond'
import axios from './axios.js'
  
 function Products() {

  let  [statusPage,setStatusPage] = useState('loadding');
  let id=useParams().id;
  let  [isLoadPage,setIsLoadPage] = useState(false);
  let [ProductsData,setData] = useState({});

  useEffect(() => {
    setIsLoadPage(false);
    axios.get('get_products/id-'+id).then((response) => {
      if (response.data.status){
        setData(response.data.data);
        
      }
      else{
        setStatusPage('notfond');
      }
      setIsLoadPage(true);
      setStatusPage('ok');

    })
    .catch((error) => {
      setStatusPage('error');
      setIsLoadPage(true);
    });


  },[id]);

  let TitlePageById = {
    'Camera':{
      name:'CAMERA ',
      title:'Video surveillance',
      desc:'Installez des caméras dans des endroits stratégiques qui peuvent transmettre en temps réel sur votre smartphone ou votre tablette à tout moment et en tout lieu.'},

    'lock':{
      name:'Access control',
      title:'Access control intelligente',
      desc:'Programmez votre maison pour verrouiller toutes les portes et fenêtres avant d\'aller vous coucher, ou lorsque vous partez au travail, afin de ne jamais être confronté à l\'angoisse de l\'oubli. '
    },

    'eclairage':{
      name:'Éclairage et stores',
      title:'de surveillance intelligente WIFI',
      desc:'Imaginez que vous puissiez contrôler tous vos éclairages intérieurs et extérieurs ainsi que vos stores d\'une seule touche.'
    },

    'vocal':{
      name:'l\'assistant vocal',
      title:'l\'assistant vocal intelligente',
      desc:'Qui a apprécié une grande expérience avec l\'assistant vocal car il prend en charge de nombreuses tâches'
    },
  };

  return (
  	<>

      <Header
       descHeader2={TitlePageById[id].desc}
       titleHeader={TitlePageById[id].name}
       descHeader="&nbsp;&nbsp;"
       classHeader="Header-products"
       isLoadPage={isLoadPage}
       />


       {statusPage=='ok' && <TabBarCat /> }
       
      {(isLoadPage) && ProductsData[id]!=undefined && <RowProducts data={ProductsData} cat={id} />}
       
       {statusPage=='notfond' && <NotFond />}

       {statusPage=='error' && <div className="container"><br /><br /><br /><h1>Une erreur s'est produite, veuillez réessayer</h1><br /><br /><br /></div>}
      
       
       <Footer />

     </>);
  }

 export default Products;