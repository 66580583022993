                                                                                                                                                                              import { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';


 function NotFond() {
  return (
  	<div className="not-fond">
      <Container>

      	<section>
      		<h1>Erreur de page introuvable</h1>
       	</section>

	   </Container>

     </div>
  );
  }

 export default NotFond;