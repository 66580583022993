import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/esm/Container';
import logo from '../logo.png';
import imgSmart from '../smart-home.jpg'
import styles  from './Header.css' 
import camera from '../camera.png'
import vocale from '../vocal.png'
import shely from '../shely.png'
import tuya from '../tuya.png'
import loader_img from '../images/loader.svg';

function Header(props) {


 let [headerTitle,setHeaderTitle] = useState('SLATE PROJECTS SARL');
 let [headerDesc,setHeaderDesc] = useState('');
 let [headerDesc2,setHeaderDesc2] = useState(''); 
 let [toggleMenu,setToggleMenu] = useState('block');  

 let [isHome,setisHome] = useState(false);
  let [loadPage,setLoadPage] = useState(true);

 let [classHeader,setClassHeader] = useState('Header-p');

 let [Hashing,setHashing] = useState(1);

 useEffect(function (){


  if (window.innerWidth > 1000)
    setToggleMenu('block');
  else
    setToggleMenu('none');

  setLoadPage(true);

  

  if (props.dataHome != undefined){
    setHeaderDesc(props.dataHome.header_title);
    setHeaderDesc2(props.dataHome.header_desc); 
  }
  

  if (props.titleHeader != undefined)
     setHeaderTitle(props.titleHeader);

   if (props.descHeader != undefined)
     setHeaderDesc(props.descHeader);

   if (props.descHeader2 != undefined)
     setHeaderDesc2(props.descHeader2);


   if (props.classHeader != undefined)
     setClassHeader(props.classHeader);


   if (props.isHome == true)
      setisHome(true);

   if (props.isLoadPage==undefined){
      let timLoadPage = setTimeout(()=>{
        setLoadPage(false);
        clearTimeout(timLoadPage);
    },2000);
   }


        

 },[Hashing,window.location.href,props.dataHome,props.titleHeader,props.descHeader2]);


 const toggleMenuHandler = () =>{
      if (toggleMenu == 'none')
      setToggleMenu('block');
      else
      setToggleMenu('none');
 }


  return (
    <>
    <header style={ props.imageHeader != undefined ? { backgroundImage: `url(${props.imageHeader})`} : {}} className={classHeader}>
      
      {(loadPage==true && !props.isLoadPage) && <div className="loadpage"><img src={loader_img} /></div>}
      <div className="header-nav">
        <Container>
          
          <div className="header-logo">
          <Link to="/"><img src={logo} /></Link>
          </div>

          <nav className="header-nav-menu">
            <ul style={{display:toggleMenu}}>
              <li><Link to="/">ACCUEIL</Link></li>
              <li className="produit-no-mobile">
                <a href="#">Les produits</a>
                
                <ul className="catagery-float">
                  <li>
                  <i className='fa fa-caret-up'></i>
                    <div className="catagery-float-in">
                      <div style={{verticalAlign: 'top',width:'150px',display:'inline-block',background:'#63175626',padding:'10px',textAlign:'center'}}>
                        <img src={camera} />
                        <Link style={{color:'#641756'}} to="/products/Camera">CAMERA</Link>
                        <p style={{color:'#641756'}}>Caméras avec caractéristiques spéciales 360 degrés et haute qualité</p>
                      </div>
                      
                      &nbsp;&nbsp;

                      <div style={{verticalAlign: 'top',width:'150px',display:'inline-block',background:'#4a42ec21',padding:'10px',textAlign:'center'}}>
                        <img src={vocale} />
                        <Link style={{color:'#3024d2'}} to="/products/vocal">ENCEINTE VOCALE</Link>
                        <p style={{color:'#3024d2'}}>Assistant intelligent Alexa, Google Home</p>
                      </div>

                      &nbsp;&nbsp;

                      <div style={{verticalAlign: 'top',minHeight: '226px',width:'170px',display:'inline-block',background:'#1987540f',padding:'10px',textAlign:'center'}}>
                        <img src={shely} />
                        <div style={{textAlign:'left'}}><Link style={{color:'#198754',padding:'10px 0'}} to="/products/eclairage">Éclairage et stores</Link></div>
                        <p style={{color:'#198754'}}>Glissières intégrées avec interrupteurs pour le contrôle</p>
                      </div>
                      &nbsp;&nbsp;

                      <div style={{verticalAlign: 'top',width:'150px',display:'inline-block',background:'#ffc10721',padding:'10px',textAlign:'center'}}>
                        <img src={tuya} />
                          <Link style={{color:'#ff9500'}} to="/products/lock">Access control</Link>
                        <p style={{color:'#ff9500'}}>Les serrures intelligentes offrent protection et contrôle</p>
                      </div>
                      
                    </div>
                  </li>
                </ul>
              </li>

              <li className="products-mobile">
                <a className="hideMobile" href="#">Les produits</a>
                <ul class="Catergy-block">
                <Link  to="/products/Camera">CAMERA</Link>
                <Link  to="/products/vocal">ENCEINTE VOCALE</Link>
                <Link  to="/products/eclairage">Éclairage et stores</Link>
                <Link  to="/products/lock">Access control</Link>
                </ul>
              </li>
              <li>
                <a className="hideMobile" href="#">Les services</a>
                <ul class="Catergy-block">
                  <li><Link to="/electricite">Electricité</Link></li>
                  <li><Link to="/Solai">énergie solaire</Link></li>
                </ul>
              </li>
              <li><Link to="/Contact"> Contactez nous</Link></li>
              
            </ul>


            <div onClick={toggleMenuHandler} className="navbar-toggle"><button type="button"  data-toggle="collapse" data-target=".sidebar-collapse">
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
              </div>
          </nav>

        </Container>
        <div className="clr"></div>
      </div>

      <section className="content-header">
        <Container>
          <div style={{position:'relative'}}>
            <div className="row">
              <div className="content-header-in col-md-12">
                <div className="content-header-in col-md-7">
                  <h3 style={{fontSize:"35px;"}} className="header-head-bigg">{headerDesc}</h3>
                </div>
              </div>
            </div>

            <div className="content-header-info">
              <div style={{display:'none'}} className="header-info-item-start">
                 {isHome && 
                   <h4><span className="head-string">Excellente</span> 
                  <i className="fa fa-star"></i> 
                  <i className="fa fa-star"></i> 
                  <i className="fa fa-star"></i> 
                  <i className="fa fa-star"></i> 
                  <i className="fa fa-star"></i> 
                  <span style={{fontSize:'14px'}} className="head-string">AU CAPITAL DE 100.000,00 DH</span>
                </h4>}
                
              </div>


              <section className="header-head-paraghraph">

                <div className="row">
                  <div className="content-header-in col-md-12">
                    {!isHome ? 
                    <div style={{color:'#c4fffd'}} className="content-header-in col-md-6 not-home">
                        
                        <h1><b>{headerDesc}</b></h1>
                        <h3 style={{color:'#fff',fontFamily:'Lato'}}>{headerDesc2}</h3>
                      </div>
                      :
                       <div style={{color:'#c4fffd'}} className="content-header-in col-md-8">
                        
                        
                        <h5 style={{color:'#fff',marginTop:'20px',fontFamily:'Lato'}}>{headerDesc2}</h5>
                      </div>
                    }

                  </div>
                </div>

              </section>
              
            </div>

            {isHome && 
              <div className="slid-show-img">
                <img src={imgSmart} />
              </div>
             }

            </div> 
        </Container>
      </section>
     
    </header>
    </>
  );
}

export default Header;
