import { useEffect, useRef, useState } from 'react';
import imgRide from '../../rideu.png';
import access_work from '../../access_work.jpg';
import access_work2 from '../../access_work2.jpg';



function Slider() {

  let [widthAnimat,setWidthAnimat] = useState(50);
  let [mouseUp,setmouseUp] = useState('not');
  
  useEffect(() => {
    
    let imfFix = document.getElementById('get_img_fix');
        let starLeft = (document.body.offsetWidth-imfFix.offsetWidth)/2;
        let clientAll = (imfFix.offsetWidth/2);
        setWidthAnimat((clientAll*100)/imfFix.offsetWidth);

        console.log(access_work2);

  },[]);
  

  let resizeTol = (e) => {
    if (mouseUp=='ok'){
        let imfFix = document.getElementById('get_img_fix');
        let starLeft = (document.body.offsetWidth-imfFix.offsetWidth)/2;
        let clientAll = (e.clientX-starLeft);
        setWidthAnimat((clientAll*100)/imfFix.offsetWidth);
    }

  }

  return (
    <>
       <div className="slide-img-custom">
        <div id="get_img_fix" onMouseMove={resizeTol} onMouseUp={() => setmouseUp('not')} style={{backgroundImage: 'url('+access_work+')'}}  className="slide-img"></div>
        
        <div onMouseMove={resizeTol} onMouseUp={() => setmouseUp('not')} style={{backgroundImage: 'url('+access_work2+')',width:widthAnimat+'%'}} className="slide-img"></div>
        <span onMouseDown={() => setmouseUp('ok')}  onMouseUp={() => setmouseUp('not')} style={{left:widthAnimat+'%'}} className="resize"></span>
       </div>
    </>
  );
}

export default Slider;
