import { useEffect, useRef, useState } from 'react';
import imgRide from '../../rideu.png';
import imgAlexa from '../../images/eml/alexa.jpg';
import lampOff from '../../images/eml/lamp-off.jpg';
import lampOn from '../../images/eml/lamp-on.jpg';
import fenetreSalon from '../../images/eml/fenetre_salon.png';


function Alexa() {

  let [widthAnimat,setWidthAnimat] = useState(50);
  let [mouseUp,setmouseUp] = useState('not');
  let [lamOffSta,setLamOffSta] = useState('off');
  let [onLampSt,setLampSt] = useState('Allumez les lampes');
  let [fenetreOpen,setFenetreOpen] = useState('off');
  let [opnfntreSt,setOpnfntreSt] = useState('Ovrire la fenetre');
  
  useEffect(() => {

  },[]);

  const ToogleLAmp = () => {
    if (lamOffSta== 'off'){
      setLamOffSta('on');
      setLampSt('Éteignez les lampes');
    }
    else
    {
      setLamOffSta('off');
      setLampSt('Allumez les lampes');
    }
       
  }
  

  const ToogleLFenetre = () => {
    if (fenetreOpen== 'off'){
      setFenetreOpen('on');
      setOpnfntreSt('Fermé la fenetre');
    }
    else
    {
      setFenetreOpen('off');
      setOpnfntreSt('Ouvrire la fenetre');
    }
       
  }



  return (
    <>
      <img width="100%" src={imgAlexa} />

          {lamOffSta == 'off' && <div className="offLamp"></div>}
        

       <div className="lamp-show">

        <img style={{display:lamOffSta=='on' ? 'none':'block'}} src={lampOff} />
        <img style={{display:lamOffSta=='off' ? 'none':'block'}}  src={lampOn} />
       </div>

       <div className="fenetre_salon">
          <img  style={{display:fenetreOpen=='off' ? 'none':'block'}} src={fenetreSalon} />
       </div>

       <div className="alexa-void">
          <i className="fa fa-caret-up alexa-void-arrow"></i>
          <div className="item-alexa" onClick={ToogleLAmp}><i className="fa fa-microphone"></i> {onLampSt}</div> 
          <div className="item-alexa" onClick={ToogleLFenetre}><i className="fa fa-microphone"></i> {opnfntreSt}</div> 
       </div>
    </>
  );
}

export default Alexa;
