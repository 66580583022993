import './footer.css';
import { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';  
import logo from '../logo.png';
import { Link } from 'react-router-dom';
function Footer(props) {



  return (
    <div className="footer">

      <Container>

        <div className="row">
          <div className="col-md-6 item-footer-logo">

            <div className="logo-footer"><img src={logo} /></div>
            <div className="clr"></div>
            <p className="content-footer">
              {props.desc}
            </p>

            <div className="info-soc">
              <div><i className="fa fa-map-marker"></i>  MADINAT EL WAHDA bloc (g) - LAAYOUNE en face de la sté big mat Food de l'autre coté du jardin</div>
               <div><i className="fa fa-map-marker"></i>  PHONE: +212708797374</div>
            </div>

          </div>


          <div className="col-md-3 item-footer-links">

            <h3>Categery</h3>

            <ul>
              
              <li> <Link to="/products/Camera">CAMERA</Link></li>
              <li><Link to="/products/lock">Access control</Link></li>
              <li><Link to="/products/vocal">ENCEINTE VOCALE</Link></li>
              <li><Link  to="/products/eclairage">Éclairage et stores</Link></li>
            </ul>
          
          </div>



          <div className="col-md-3 item-social-footer">
              <h3>Social</h3>

              <ul className="Social">
              <li><a href="#"><i className="fa fa-facebook"></i> Facebook</a></li>
              <li><a href="#"><i style={{color:'#910cd3'}} className="fa fa-instagram"></i> Instagram</a></li>
              <li><a href="#"><i style={{color:'#337c07'}} className="fa fa-whatsapp"></i> Whatsapp</a></li>
            </ul>
            
          </div>
        </div>

      </Container>

    </div>
  );
}

export default Footer;
