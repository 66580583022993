import { useEffect, useRef, useState } from 'react';


function NotPage() {



  return (
    <div className="404">
        p age not fond
    </div>
  );
}

export default NotPage;
