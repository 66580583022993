import { useEffect, useRef, useState } from 'react';
import imgRide from '../../rideu.png';
import lamp_salon from '../../images/eml/lamp_salon.jpg';
import lampSalon2 from '../../images/eml/lamp-salon2.jpg';
import Phone from '../../images/phone.png'

function Lamp() {

  let [lampShow,setLampShow] = useState('visible');
  
  useEffect(() => {

  },[]);
  



  return (
    <>
       
        <div className="lamp-div-over">
          <img src={lamp_salon} />
        </div>
        <div style={{visibility: lampShow}} className="lamp-show2">
          <img src={lampSalon2} />
       </div>

       <div className="imulator-phone">
          <div className="phon-conten">
            <span style={{background:'#dc3545'}} onClick={() => { setLampShow('hidden') }} className="fa fa-power-off button-phone"></span>
            <div></div>
            <span style={{background:'#159f21'}} onClick={() => { setLampShow('visible') }} className="fa fa-lightbulb-o button-phone"></span>
         </div>
       </div>
    </>
  );
}

export default Lamp;
