import { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import cssStyle from './tab-bar-cat.css';
import {Link} from 'react-router-dom'


 function TabBarCat() {
  return (
  	<div className="tab-bar-cat">
      <Container>

      	<nav className="nav-tolbar">
      		<ul>
      			<li className="parent-tolbar-child"><h2>CATEGERY</h2></li>
            <li><Link style={{color:'#3024d2'}} to="/products/Camera">CAMERA</Link></li>
      			<li><Link style={{color:'#3024d2'}} to="/products/eclairage">{'Éclairage et stores'.toUpperCase()}</Link></li>
      			<li><Link style={{color:'#3024d2'}} to="/products/lock">{'Access control'.toUpperCase()}</Link></li>
      			<li><Link style={{color:'#3024d2'}} to="/products/vocal">ENCEINTE VOCALE</Link></li>
      		</ul>
      		<div className="clr"></div>
      	</nav>

	   </Container>

     </div>
  );
  }

 export default TabBarCat;